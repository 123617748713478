<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <AffectationTab />
  </v-card>
</template>

<script>
import AffectationTab from "@/components/AffectationComponent/AffectationTab.vue";

export default {
  components: {
    AffectationTab
  },
};
</script>
